(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/assets/javascripts/pages/page-play.js') >= 0) return;  svs.modules.push('/modules/oddset/assets/javascripts/pages/page-play.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const {
  useMemo,
  useContext
} = React;
const {
  useKambiToken
} = svs.components.oddset.kambiAuthentication;
const {
  useShapeEnv
} = svs.components.oddset.shapeEnv;
const {
  usePersonalizedContentAllowed
} = svs.components.oddset.personalizedContent;
const {
  addToCart
} = svs.components.oddset.selfserviceIntegration;
const {
  NavContext
} = svs.oddset.components.nav_context;
const {
  useSelfserviceBackButton
} = svs.oddset.components.hooks;
const {
  ShapeComponentConfigTypes
} = svs.oddset.components.common.constants;
const {
  ShapeGamesClient
} = svs.oddset.components.shape_games_client;
const {
  login,
  logout,
  updateBalance,
  updatePageMetaData,
  showGrasrotenDialog
} = svs.oddset.components.common.utils;
const getTimeZone = svs.oddset.components.common.getTimeZone;
const {
  onPaymentError
} = svs.components.oddset.clientErrorHandler.payment;
const {
  shapeTransaction,
  onBetReceiptAction,
  onClearBetslip,
  onAddOutcomeToBetslip,
  onRemoveOutcomeFromBetslip,
  onSearchQueryResolved
} = svs.components.oddset.analytics;
const baseUrl = svs.core.data.baseUrl;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const logger = new svs.core.log.Logger('modules:oddset:PlayPage');
const PlayPage = () => {
  useSelfserviceBackButton();
  const {
    token,
    hashedPunterId
  } = useKambiToken();
  const {
    shapeEnv
  } = useShapeEnv();
  const {
    setActivePathname
  } = useContext(NavContext);
  const {
    isPersonalizedContentAllowed
  } = usePersonalizedContentAllowed();

  const sportsbookConfig = useMemo(() => {
    return {
      props: {
        playerSessionId: token ? token : '',
        session: token ? '123' : '',
        apiEnvironment: shapeEnv,
        isRetail: isSelfService,
        entryPath: baseUrl,
        hashedPunterId,
        personalizedContentAllowed: isPersonalizedContentAllowed,
        retailClientData: isSelfService ? {
          market: 'SE',
          timezone: getTimeZone(),
          productId: 50,
          destination: 'Kambi'
        } : undefined
      },
      eventListeners: {
        pageView: setActivePathname,
        logout,
        login,
        pageMetaData: updatePageMetaData,
        refreshBalance: updateBalance,
        onRetailBet: addToCart,
        onBetPlacementSuccess: data => {
          logger.info('Event onBetPlacementSuccess, received data:', data);
          shapeTransaction(data);
        },
        onNonInteractiveBetError: onPaymentError,
        onBetReceiptAction: !isSelfService ? data => {
          onBetReceiptAction(data);
          showGrasrotenDialog();
        } : undefined,
        onClearBetslip,
        onAddOutcomeToBetslip,
        onRemoveOutcomeFromBetslip,
        onSearchQueryResolved
      }
    };
  }, [token, shapeEnv, setActivePathname, isPersonalizedContentAllowed, hashedPunterId]);
  return React.createElement(ShapeGamesClient, {
    componentName: ShapeComponentConfigTypes.SPORTSBOOK,
    shapeConfig: sportsbookConfig,
    version: "2.0.22"
  });
};
setGlobal('svs.oddset.pages.PlayPage', PlayPage);

 })(window);